import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { CSVLink } from 'react-csv';
import {
	csvData,
	csvHeaders,
	mtdCsvData,
	mtdCsvHeaders,
	TemplateType,
} from './types';
import { useGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';
import { BrandedButton } from 'common/components/BrandedButton';
import { useGetFundQuery } from 'api/redux/services/fundApi';
import { useGetTemplatesQuery } from 'api/redux/services/capitalActivityApi';

const CsvButton = styled(BrandedButton)(({ theme }) => ({
	border: '#bfbfbf 1px solid',
	maxWidth: 'fit-content',
	margin: '10px',
	padding: '3px',
}));

const Select = styled(Box)(({ theme }) => ({
	width: '25%',
	padding: '10px',
}));

const SelectLabel = styled(Box)(({ theme }) => ({
	padding: '4px',
}));

export const CSVTemplate: React.FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const { data: investors } = useGetInvestorsQuery(currentFund.id);
	const { data: fund } = useGetFundQuery(currentFund.id);
	const { data: templates } = useGetTemplatesQuery();

	if (!investors || !templates) return <></>;

	const getTemplateId = (templates, fund) => {
		const template = templates.find(
			(template) => template.id === fund.templateId,
		);
		return template?.templateId;
	};

	const MTD_YTD_LTD_TEMPLATE = TemplateType.MTD;
	const CAPITAL_ACTIVITY_FILENAME = 'CapitalActivityTemplate.csv';

	const getCSVReportConfig = (investors) => {
		const template = getTemplateId(templates, fund);
		switch (template) {
			case MTD_YTD_LTD_TEMPLATE:
				return {
					data: mtdCsvData(investors),
					headers: mtdCsvHeaders,
					filename: CAPITAL_ACTIVITY_FILENAME,
				};

			default:
				return {
					data: csvData(investors),
					headers: csvHeaders,
					filename: CAPITAL_ACTIVITY_FILENAME,
				};
		}
	};

	const csvReport = getCSVReportConfig(investors);

	return (
		<Box>
			<CsvButton>
				<CSVLink {...csvReport}> Download Template </CSVLink>
			</CsvButton>
		</Box>
	);
};

export default CSVTemplate;
