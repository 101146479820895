import { ICapitalActivityData } from '../capital-activity.types';
import { IFundCapitalActivity } from '../fund-capital-activity.types';
import {
	CapitalCommitmentTableLabels,
	CellValueFormats,
	EColumnTitle,
	ETimePeriodSuffixes,
	FallbackKeys,
	IColumn,
	INullBehavior,
	IRow,
	RowLabels,
	RowNames,
	RowNamesToFallbackKeys,
	TemplateType,
} from './types';

export const renderDollarSign = (
	row: IRow,
	column: IColumn,
	templateType: TemplateType,
) => {
	const renderPolicy = {
		[TemplateType.ITD]: () => {
			if (
				CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
				column.timePeriodSuffix == ETimePeriodSuffixes.PTD
			)
				return '';

			return row.valueFormats.includes(CellValueFormats.CURRENCY) ? '$' : '';
		},
	};

	return renderPolicy[templateType]();
};

export const cellValue = (
	row: IRow,
	column: IColumn,
	attributeData: ICapitalActivityData[],
	fundData: IFundCapitalActivity,
	templateType: TemplateType,
): string | undefined => {
	const { text: cellLevel } = column.title[0];
	const CASData: ICapitalActivityData[] = fundData.attributeData;

	if (
		CapitalCommitmentTableLabels.includes(row.label.text as RowNames) &&
		column.timePeriodSuffix === ETimePeriodSuffixes.ITD &&
		templateType === TemplateType.ITD
	)
		return '';

	const cell = findCell(row, column, CASData);

	return formatCell(row, cell);
};

export const findInAttributeData = (
	rowLabel: string,
	CASData: ICapitalActivityData[],
	timePeriodSuffix: ETimePeriodSuffixes,
): ICapitalActivityData | undefined => {
	const label = RowLabels[rowLabel];

	let data =
		CASData.find((f) => f.name === `${label} (${timePeriodSuffix})`) ||
		CASData.find((f) => f.name === `${label}`);

	if (!data) {
		const fallbackKey = RowNamesToFallbackKeys[rowLabel];
		const keysToFind = FallbackKeys[fallbackKey];

		if (keysToFind) {
			for (let i = 0; i < keysToFind.length; i++) {
				const key = keysToFind[i];

				data =
					CASData.find((f) => f.name === `${key} (${timePeriodSuffix})`) ||
					CASData.find((f) => f.name === `${key}`);

				if (data) break;
			}
		}
	}

	return data;
};

const findCell = (
	row: IRow,
	column: IColumn,
	CASData: ICapitalActivityData[],
): ICapitalActivityData | undefined => {
	const { timePeriodSuffix } = column;

	switch (timePeriodSuffix) {
		case ETimePeriodSuffixes.ITD:
			return findInAttributeData(
				row.label.text,
				CASData,
				ETimePeriodSuffixes.ITD,
			);
		case ETimePeriodSuffixes.PTD:
			return findInAttributeData(
				row.label.text,
				CASData,
				ETimePeriodSuffixes.YTD,
			);
		default:
			return undefined;
	}
};

const formatCell = (
	row: IRow,
	cell?: ICapitalActivityData,
): string | undefined => {
	if (!cell || !cell.value) return nullValue(row.nullBehavior);
	const { valueFormats } = row;

	if (
		[CellValueFormats.CURRENCY, CellValueFormats.NUMBER].some((format) =>
			valueFormats.includes(format),
		)
	)
		return formatNumber(cell.value, row.nullBehavior);

	return cell.value;
};

/**
 * For dollar sign and dash we return dash, because the dollar sign now is applied in the grid inside
 * the CASTableCell component.
 */
const nullValue = (nullBehavior: INullBehavior): string => {
	switch (nullBehavior) {
		case INullBehavior.DASH:
			return '-';
		case INullBehavior.DOLLAR_SIGN_AND_DASH:
			return '-';
		case INullBehavior.BLANK:
			return '';
		case INullBehavior.ZERO:
			return '0';
		default:
			return '';
	}
};

const formatNumber = (
	value: number | string,
	nullBehavior: INullBehavior,
): string => {
	if (isZero(value)) return nullValue(nullBehavior);

	return currencyFormatter.format(Number(value)).replace('$', '');
};

export const isZero = (val: number | string | undefined | null): boolean => {
	if (val === undefined || val === null || val === '') return true;

	const numValue: number = typeof val === 'number' ? val : parseInt(val);

	return Math.abs(numValue) === 0;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	currencySign: 'accounting',
	maximumFractionDigits: 0,
});
