import { IFundCapitalActivity } from '../fund-capital-activity.types';
import { findInAttributeData, isZero } from './cellValue';
import {
	EHiddenBehavior,
	ETimePeriodSuffixes,
	ICapitalActivityData,
	IRow,
	TemplateHelper,
} from './types';

export class MTDTemplateHelper implements TemplateHelper {
	shouldRenderHiddableRow(
		row: IRow,
		CASData: ICapitalActivityData[],
		fundData: ICapitalActivityData[],
	): boolean {
		const cellValue = findInAttributeData(
			row.label.text,
			CASData,
			ETimePeriodSuffixes.YTD,
		);

		if (!isZero(cellValue?.value)) return true;

		if (
			isZero(cellValue?.value) &&
			row.hiddenBehavior?.includes(
				EHiddenBehavior.SHOW_AS_ZERO_WHEN_IN_FUND_LEVEL,
			)
		) {
			const fundCellValue = findInAttributeData(
				row.label.text,
				fundData,
				ETimePeriodSuffixes.YTD,
			);

			if (!isZero(fundCellValue?.value)) return true;
		}

		return false;
	}
}
