import { FC } from 'react';
import { Box } from '@mui/material';
import { ColumnLabels, TableCell, TableRow } from './styles';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import { getInvestorColumns, getReportingPeriod } from './utils';
import { TableHead } from '@mui/material';
import { ITableHeadersProps, IColumn } from './types';
import { ETimePeriodSuffixes } from '../types';

const FirstHeaderRow: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);
	const columns = [...getInvestorColumns(capitalActivityTemplate)];
	const reportingDate = reportingPeriod?.replace('Period ending ', '');
	reportingPeriod = getReportingPeriod(reportingDate ? reportingDate : '');

	return (
		<TableRow
			style={{
				backgroundColor: `${sponsorColor}`,
			}}
		>
			<TableCell></TableCell>
			{columns.map(
				(column: IColumn, i) =>
					column.title && (
						<TableCell
							key={i}
							style={{
								width: '35rem',
								padding: '10px 0px 10px 0px',
							}}
						>
							<ColumnHeader
								column={column}
								reportingDate={reportingDate}
								reportingPeriod={reportingPeriod}
							></ColumnHeader>
						</TableCell>
					),
			)}
		</TableRow>
	);
};

const getMTDHeader = (reportingDate) => {
	const month = new Date(reportingDate).toLocaleString('en-US', {
		month: 'long',
	});
	const year = new Date(reportingDate).getFullYear();
	return `${month} 1, ${year}`;
};

const ColumnHeader = ({ column, reportingDate, reportingPeriod }) => {
	const isInception = column.title[1].text.includes('inception');
	let labelText = isInception ? `Inception - ` : reportingPeriod + ' - ';
	if (column.timePeriodSuffix === ETimePeriodSuffixes.MTD) {
		labelText = getMTDHeader(reportingDate) + ` - `;
	}

	return (
		<Box>
			<ColumnLabels key={`${column.title[1].text}-1`}>{labelText}</ColumnLabels>
			<ColumnLabels key={`${column.title[1].text}-2`}>
				{reportingDate}
			</ColumnLabels>
		</Box>
	);
};

export const MTDCASTableHeaders: FC<ITableHeadersProps> = ({
	capitalActivityTemplate,
	reportingPeriod,
}) => {
	return (
		<TableHead>
			<FirstHeaderRow
				capitalActivityTemplate={capitalActivityTemplate}
				reportingPeriod={reportingPeriod}
			/>
		</TableHead>
	);
};
