import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Document } from 'app/types/document.types';
import {
	useLazyGetFundDocumentQuery,
	useLazyGetInvestorDocumentQuery,
} from 'api/redux/services/documentApi';
import {
	onChangeFund,
	onChangeInvestor,
	onChangeSponsor,
	selectDropdown,
} from 'api/redux/DropdownReducer';
import { ISponsorGrant } from 'api/redux/services/userApi';
import { ERoutes } from 'App';
import {
	onRemoveRedirectURL,
	onSetRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import _ from 'lodash';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';
import {
	onSetCurrentDocument,
	onSetFromEmailNotification,
} from 'api/redux/DocumentsReducer';
import { DocumentTableKeys } from './types';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { Alert } from '@mui/material';
import LoadingSpinner from 'common/components/LoadingSpinner';

/**
 * Since this component only renders when coming from a notification, here we take care of setting the proper
 * value on the application state for:
 * - currentSponsor
 * - currentFund
 * - currentInvestor
 *
 * The reason for this is it is necessary to display the correct information before redirtecting the user to
 * the 'document-view' route.
 */
export const SpecificDocument: FC = () => {
	const { documentId } = useParams<{ documentId: string }>();
	const { search } = useLocation();
	const { currentUser } = useLocalUser();
	const params = new URLSearchParams(search);
	const tableKey = params.get('t');
	const sponsorId = params.get('S');
	const fundId = params.get('F');
	const investorId = params.get('I');
	const dispatch = useDispatch();
	const [getInvestorDocument, investorDocument] =
		useLazyGetInvestorDocumentQuery();
	const [getFundDocument, fundDocument] = useLazyGetFundDocumentQuery();
	const [sponsorSet, setSponsorSet] = useState<boolean>(false);
	const [fundSet, setFundSet] = useState<boolean>(false);
	const [investorSet, setInvestorSet] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [document, setDocument] = useState<Document | undefined>(undefined);
	const { grants } = useSelector(selectDropdown);
	const {
		availableSponsors,
		availableFunds,
		availableInvestors,
		currentFund,
		currentInvestor,
	} = grants;
	const redirectURL = useSelector(redirectURLState);
	const navigate = useNavigate();
	const isFundLevel = tableKey === DocumentTableKeys.FT;

	const saveURL = () => {
		const { pathname, search } = window.location;
		dispatch(onSetRedirectURL(`${pathname}${search}`));
	};

	/**
	 * Validate the user accepted the terms and conditions. If not the user will have initialLogin attribute set as 'false'
	 * - If the user did not accept the terms and conditions, we redirect it to the dashboard, where there is a check for it
	 *   and a pop up will show up to be read and accepted.
	 * - This occurs only onec, at component load.
	 */
	useEffect(() => {
		if (!currentUser?.initialLogin) {
			saveURL();
			navigate(ERoutes.Dashboard);
		}

		if (currentUser?.initialLogin && redirectURL?.includes('documents/')) {
			dispatch(onRemoveRedirectURL({}));
		}
	}, []);

	/**
	 * Validate the table is determined using the URL parameters
	 * - This occurs at load time, only once
	 */
	useEffect(() => {
		if (!tableKey) {
			setError('An error has occurred');
			return;
		}

		if (!Object.keys(DocumentTableKeys).includes(tableKey))
			setError('An error has occurred');
	}, [tableKey, setError]);

	/**
	 * Set the currentSponsor in application state using the parameter sent from the notification link in the URL
	 */
	useEffect(() => {
		if (_.isEmpty(availableSponsors) || !sponsorId) return;

		const sponsor: ISponsorGrant | undefined = availableSponsors.find(
			(s) => s.id === parseInt(sponsorId),
		);

		if (!sponsor) {
			navigate(ERoutes.Dashboard);
			return;
		}

		dispatch(onChangeSponsor(sponsor));
		setSponsorSet(true);
	}, [availableSponsors, dispatch, navigate, sponsorId]);

	/**
	 * Set the currentFund in application state using the parameter sent from the notification link in the URL
	 * - It needs to be executed after setting the currentSponsor, since the availableFunds state variable is
	 *   updated right after it.
	 */
	useEffect(() => {
		if (!sponsorSet || _.isEmpty(availableFunds) || !fundId) return;

		const fund = availableFunds.find((f) => f.id === parseInt(fundId));

		if (!fund || (fund.id === currentFund.id && fundSet)) return;

		dispatch(onChangeFund(fund));
		setFundSet(true);
	}, [sponsorSet, availableFunds, dispatch, fundId]);

	/**
	 * Set the currentInvestor in the application state using the parameter sent from the notification link in the URL
	 * - It needs to be executed after setting the currentSponsor and the currentFund, since the availableInvestors
	 *   state variable is updated right after the currentFund is set.
	 */
	useEffect(() => {
		if (
			(isFundLevel && !fundSet) ||
			!investorId ||
			_.isEmpty(availableInvestors)
		)
			return;

		const investor = availableInvestors.find(
			(i) => i.id === parseInt(investorId),
		);

		if (!investor || (investor.id === currentInvestor.id && investorSet))
			return;

		dispatch(onChangeInvestor(investor));
		setInvestorSet(true);
	}, [availableInvestors, dispatch, investorId, fundSet]);

	/**
	 * Fetch the document using the API depending on the level (fund or investor)
	 * - This only should occure when the current fund is set in the application state if it is a fund level document
	 * - Or if the current investor is set in the application state and it is an investor level document
	 */
	useEffect(() => {
		if ((isFundLevel && !fundSet) || (!isFundLevel && !investorSet)) return;

		if (isFundLevel) {
			getFundDocument(Number(documentId));
			return;
		}

		getInvestorDocument(Number(documentId));
	}, [documentId, getFundDocument, getInvestorDocument, fundSet, investorSet]);

	useEffect(() => {
		if (!fundDocument.data) return;

		setDocument(fundDocument.data);
	}, [fundDocument.data]);

	useEffect(() => {
		if (!investorDocument.data) return;

		setDocument(investorDocument.data);
	}, [investorDocument.data]);

	/**
	 * Find the document, set it into the application state and redirect the user to the 'document-view' route
	 *
	 * Steps here are:
	 * - When the state variables for currentSponsor, currentFund and currentInvestor are all set,
	 * - and we are sure we have a documentId form URL params,
	 * - we proceed to get the document from the available docs
	 * - and then set it into th application state as currentDocument
	 * - to finally redirect the user to the 'document-view' route that depends on this variable to be rendered
	 *   correctly
	 */
	useEffect(() => {
		if (!document) return;

		dispatch(onSetCurrentDocument(document));
		dispatch(onSetFromEmailNotification(true));
		navigate(ERoutes.DocumentView);
	}, [document]);

	if (error) {
		return (
			<MainSidebarContainer>
				<Alert severity="error" variant="filled">
					{error}
				</Alert>
			</MainSidebarContainer>
		);
	}

	return <LoadingSpinner />;
};

export default SpecificDocument;
